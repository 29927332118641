<template>
    <div class="main_container">
        <NavBar/>
        <div class="about_container">
            <!--About head-->
            <div class="about_title-container">
                <div class="about_head-content">
                    <div class="about_title">
                        About Rezi Leads
                    </div>
                    <div class="about_description">
                        As a tech-enabled solution, Rezi Leads strives to be the best real estate lead generation
                        software on the market. Our goal is to assist as many real estate agents as possible in growing
                        their businesses.
                    </div>
                    <div class="about_get-btn-container">
                        <b-button class="about_get-btn" @click="startGettingLeads()">Start Getting Leads</b-button>
                    </div>
                </div>
                <div class="about_title-img"></div>
            </div>
            <!--            -->
            <div class="testimonials_subtitle">
                <div class="subtitle">By entrepreneurs, for entrepreneurs</div>
                <div class="subtitle_text">We understand how hard it is to grow a real estate business. That’s why we’ve
                    created Rezi Leads, to help you save time, be more efficient, and maximize your earnings potential.
                </div>
            </div>
            <!--About testimonials-->
            <div class="about_testimonials-part">
                <div class="testimonials_title">What real estate agents say about Rezi Leads</div>
                <div class="testimonials_carousel">
                    <carousel
                            :dots='false'
                            :autoplay="true"
                            :nav="false"
                            :items="2"
                            :loop="true"
                            :autoplayTimeout="15000"
                            :responsive="{0:{items:1}, 1440: {items: 2}, 1500: {items: 2}, 1600:{items:2}}"
                    >
                        <div class="carousel_item">
                            <div class="testimonials_img"></div>
                            <div class="testimonials_text">
                                "Rezi Leads helped me build a strong book of business. It didn’t take long for the leads
                                to start coming in. Their lead generation technology and robust CRM provides you with
                                all the tools you need to originate deals"
                            </div>
                            <h4 class="testimonials_name">Becky H.</h4>
                            <div class="testimonials_subtitle">Agent Looking For Leads + Robust CRM</div>
                        </div>
                        <div class="carousel_item">
                            <div class="testimonials_img"></div>
                            <div class="testimonials_text">
                                "I’m so excited about how Rezi Leads will help me do more deals. Even as an agent that
                                has been in the business for many years, I was able to receive a large bucket of leads
                                and was given all the tools needed to nurture these leads. This enabled me to capitalize
                                on both short term opportunities, while developing leads for the future as well."
                            </div>
                            <h4 class="testimonials_name">Trey C.</h4>
                            <div class="testimonials_subtitle">Seasoned Agent With 10+ Years of Experience</div>
                        </div>
                        <div class="carousel_item">
                            <div class="testimonials_img"></div>
                            <div class="testimonials_text">
                                “Rezi Leads is helping me generate dealflow. As a new agent, ReziLeads has saved me
                                hours with its lead generation technology and supporting CRM.“
                            </div>
                            <h4 class="testimonials_name">Jen S.</h4>
                            <div class="testimonials_subtitle">New Agent</div>
                        </div>
                        <template slot="next">
                            <div class="next_btn"><span class="next"></span></div>
                        </template>
                    </carousel>
                </div>
            </div>
            <div class="testimonials_subtitle">
                <div class="subtitle">Our agents have seen over 398% ROI and saved more than 20 hours a week,
                    and you can do the same too.
                </div>
                <div class="btn-container">
                    <b-button class="info-btn" @click="startGettingLeads()">Start Getting Leads</b-button>
                </div>
            </div>
            <!--About image-->
            <div class="about_main-img"></div>
        </div>
        <Footer/>

    </div>
</template>

<script>
    import NavBar from '../components/NavBar'
    import Footer from '../components/Footer'
    import carousel from "vue-owl-carousel";

    export default {
        name: "AboutUs",
        data() {
            return {}
        },
        components: {
            NavBar,
            Footer,
            carousel
        },
        methods: {
            startGettingLeads() {
                this.$router.push('/register')
            },
        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        width: 100%;
    }

    .about {
        &_container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & .testimonials_subtitle {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 20px 0 5px;
                @media screen and (max-width: 425px) {
                    max-width: 380px;
                }

                & .subtitle {
                    padding: 15px 0 20px;
                    font-size: 46px;
                    color: $main_blue;
                    font-weight: 600;
                    text-align: center;
                    max-width: 1010px;
                    @media screen and (max-width: 1280px) {
                        font-size: 40px;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 28px;
                    }

                    &_text {
                        font-size: 20px;
                        color: $grey;
                        max-width: 1200px;
                        width: 100%;
                        text-align: center;
                        @media screen and (max-width: 1280px) {
                            max-width: 1000px;
                        }
                        @media screen and (max-width: 425px) {
                            font-size: 18px;
                        }
                    }
                }
                & .btn-container {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    padding: 15px 0;
                    & .info-btn {
                        background: $main_blue;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 10px;
                        border: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 246px;
                        height: 58px;
                        color: $white;
                        font-size: 16px;
                        font-weight: 500;
                        @media screen and (max-width: 768px) {
                            font-size: 15px;
                            width: 220px;
                            height: 45px;
                        }

                        &:hover {
                            background: $main_blue;
                        }

                        &:focus {
                            background: $main_blue;
                            box-shadow: none;
                        }

                    }
                }
            }
        }

        &_title {
            font-size: 60px;
            font-weight: bold;
            color: $main_blue;
            @media screen and (max-width: 1600px) {
                font-size: 56px;
            }
            @media screen and (max-width: 1440px) {
                font-size: 54px;
            }
            @media screen and (max-width: 1280px) {
                font-size: 44px;
            }
            @media screen and (max-width: 1024px) {
                font-size: 42px;
            }
            @media screen and (max-width: 425px) {
                font-size: 32px;
            }

            &-container {
                max-width: 1640px;
                width: 100%;
                display: flex;
                padding-top: 100px;
                justify-content: space-between;
                @media screen and (max-width: 1700px) {
                    max-width: 1600px;
                    padding: 100px 20px 0;
                }
                @media screen and (max-width: 1600px) {
                    max-width: 1500px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 1300px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 1080px;
                    padding: 100px 0 0;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 900px;
                }
                @media screen and (max-width: 768px) {
                    max-width: 700px;
                }
                @media screen and (max-width: 425px) {
                    flex-direction: column;
                    max-width: 370px;
                }

            }

            &-img {
                width: 870px;
                height: 540px;
                background: url("~@/assets/images/about_img.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                @media screen and (max-width: 1700px) {
                    width: 780px;
                    height: 540px;
                }
                @media screen and (max-width: 1600px) {
                    width: 750px;
                    height: 520px;
                }
                @media screen and (max-width: 1440px) {
                    width: 600px;
                    height: 450px;
                }
                @media screen and (max-width: 1024px) {
                    width: 400px;
                    height: 400px;
                }
                @media screen and (max-width: 425px) {
                    width: 390px;
                    height: 300px;
                }
            }
        }

        &_description {
            color: $grey;
            font-size: 20px;
            padding: 40px 0 55px;
            @media screen and (max-width: 1440px) {
                font-size: 18px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 16px;
            }
            @media screen and (max-width: 425px) {
                padding: 20px 0 30px;
            }
        }

        &_get-btn {
            background: $light_blue;
            color: $white;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 14px 42px;
            border: none;
            box-shadow: 0 3px 6px #00000029;
            border-radius: 10px;
            @media screen and (max-width: 425px) {
                font-size: 16px;
                padding: 10px 25px;
            }

            &-container {
                width: 100%;
            }
        }

        &_head-content {
            max-width: 780px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 1700px) {
                max-width: 740px;
            }
            @media screen and (max-width: 1600px) {
                max-width: 680px;
            }
            @media screen and (max-width: 1440px) {
                max-width: 660px;
                padding-right: 20px;
            }
            @media screen and (max-width: 1200px) {
                max-width: 600px;
            }
            @media screen and (max-width: 1024px) {
                max-width: 500px;
            }

        }

        /////////////
        &_testimonials-part {
            display: flex;
            max-width: 1640px;
            width: 100%;
            position: relative;
            padding-top: 80px;
            @media screen and (max-width: 1700px) {
                max-width: 1600px;
                padding: 80px 20px 10px;
            }
            @media screen and (max-width: 1600px) {
                max-width: 1500px;
            }
            @media screen and (max-width: 1440px) {
                max-width: 1300px;
                padding: 80px 0 10px;
            }
            @media screen and (max-width: 1280px) {
                max-width: 1080px;
            }
            @media screen and (max-width: 1024px) {
                max-width: 900px;
            }
            @media screen and (max-width: 768px) {
                max-width: 700px;
            }
            @media screen and (max-width: 425px) {
                max-width: 380px;
                flex-direction: column;
                padding-top: 20px;
            }

            & .testimonials_title {
                padding-top: 80px;
                width: 360px;
                color: $main_blue;
                font-size: 50px;
                font-weight: 500;
                @media screen and (max-width: 1600px) {
                    width: 290px;
                    font-size: 44px;
                }
                @media screen and (max-width: 1440px) {
                    font-size: 44px;
                }
                @media screen and (max-width: 1280px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 425px) {
                    padding: 5px 0 15px;
                    font-size: 26px;
                    width: 380px;
                }
            }

            & .testimonials_carousel {
                max-width: 1200px;
                width: 100%;
                overflow: hidden;
                display: flex;
                padding: 0 115px 0 80px;
                @media screen and (max-width: 1600px) {
                    max-width: 1100px;
                    padding: 0 70px 0 50px;
                }
                @media screen and (max-width: 1440px) {
                    max-width: 950px;
                    padding: 0 20px;
                }
                @media screen and (max-width: 1280px) {
                    max-width: 740px;
                }
                @media screen and (max-width: 425px) {
                    padding: 0;
                }

                ::v-deep .owl-carousel .owl-item {
                    opacity: 0;
                }

                ::v-deep .owl-carousel .owl-item.active {
                    opacity: 1;
                }

                & .next_btn {
                    position: absolute;
                    top: calc(50% - 36px);
                    right: 15px;
                    width: 82px;
                    height: 82px;
                    cursor: pointer;
                    background-position: center;
                    background-repeat: no-repeat;
                    z-index: 2;
                    background: url("~@/assets/images/right_arrow.svg");
                    @media screen and (max-width: 1440px) {
                        right: 5px;
                        width: 70px;
                        height: 70px;
                        background-position: center;
                        background-size: contain;
                    }
                    @media screen and (max-width: 1280px) {
                        right: 30px;
                    }
                    @media screen and (max-width: 768px) {
                        top: 100px;
                        right: 10px;
                    }

                    & .next {
                        width: 100%;
                        height: 100%;
                    }
                }

                & .carousel_item {
                    padding: 40px 42px;
                    width: 520px;
                    margin: 0 44px;
                    border-left: 1px solid #96A7B4;
                    @media screen and (max-width: 1440px) {
                        width: 460px;
                        padding: 30px;
                    }
                    @media screen and (max-width: 425px) {
                        width: 380px;
                        padding: 20px;
                        margin: 0 10px;
                    }

                    & .testimonials {
                        &_img {
                            width: 58px;
                            height: 45px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background: url("~@/assets/images/testimonials.svg");
                        }

                        &_text {
                            padding: 40px 10px 20px 0;
                            color: $grey;
                            font-size: 18px;
                            min-height: 280px;
                            height: 100%;
                            @media screen and (max-width: 1440px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 1024px) {
                                min-height: 240px;
                            }
                        }

                        &_name {
                            color: $main_blue;
                            font-size: 20px;
                            font-weight: bold;
                            @media screen and (max-width: 1440px) {
                                font-size: 18px;
                            }
                        }

                        &_subtitle {
                            color: $light_blue;
                            font-weight: bold;
                            font-size: 19px;
                            @media screen and (max-width: 1440px) {
                                font-size: 18px;
                            }
                        }
                    }

                }
            }
        }

        ////////////
        &_main-img {
            width: 100%;
            height: 1200px;
            background-position: center;
            background-repeat: no-repeat;
            background: url("~@/assets/images/family_img.png");
            @media screen and (max-width: 1700px) {
                background-size: cover !important;
                background-position: center;
            }
            @media screen and (max-width: 1440px) {
                height: 970px;
            }
            @media screen and (max-width: 1280px) {
                height: 800px;
            }
            @media screen and (max-width: 1024px) {
                height: 700px;
            }
            @media screen and (max-width: 425px) {
                height: 380px;
            }

        }

    }

</style>